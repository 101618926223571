/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCeit-Roj2E9eMAXguL0hj_thCiQK-RlbA",
  "appId": "1:1039619818268:web:0726e317611d95b0d170d9",
  "authDomain": "schooldog-i-app.firebaseapp.com",
  "measurementId": "G-JEE2ML5B0E",
  "messagingSenderId": "1039619818268",
  "project": "schooldog-i-app",
  "projectId": "schooldog-i-app",
  "showGaBranding": false,
  "storageBucket": "schooldog-i-app.appspot.com"
}
